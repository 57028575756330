*{
  font-family: 'Nunito', sans-serif;
}
.editorBG {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #9e9e9e;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjOWU5ZTllIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiM4ODgiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=);
  transition: left 500ms;
  height: 100vh;
  overflow-y: auto;
  /* display: flex;
    flex-direction: column;
    align-items: center;
    padding: 72px 0 20px; */
}

.editorBG .medium-editor-element {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  outline: none;
  /* margin: 13px auto; */
  box-shadow: 1px 1px 3px 1px #333;
  border-collapse: separate;
  background-color: #fff;
  width: 640px;
  min-height: 842.88px !important;
  padding: 56px;
  color: #000;
}
