* {
  font-family: "Nunito", sans-serif;
}
.round {
  border-radius: 6px;
}

.analytics-map-data-list td {
  text-align: center;
}
