.nk-files-head .nk-file-item > div.tb-head {
    font-weight: 500 !important;
    font-size: 0.8125rem !important;
}

.MuiButtonBase-root {
    outline: none;
}

.dropdown-heading-dropdown-arrow {
    margin-top: 15px;
}

.MuiFormLabel-root {
    font-family: "Nunito",sans-serif !important;
}

body {
    font-family: "Nunito",sans-serif !important;
}

h1, h2, h3, h4, h4, h5, h6 {
    font-family: "Nunito",sans-serif !important;
}