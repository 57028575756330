.zoom:hover{
        background-color: rgb(87, 87, 87);
}
.zoom:hover .imgZoom{
        filter: brightness(3);
}
.AbsoluteButton::after{
        width: 10px;
        height: 10px;
        background-color: red;
        margin-top: -15px;
        /* z-index: 99999; */
}
#buttonContainer:hover #btnTitle{
        display: block;
}
#buttonC{
        position: relative;
}
#btnTitle{
        position: absolute;
        top: 60px;
        background-color: rgb(31, 31, 31);
        display: none;
        margin: 0 auto;
        z-index: 888;
        white-space: nowrap;
        height: 30px;
        padding: 6px;
        border-radius: 4px;
        overflow: visible;
        left: 50%;
        transform: translateX(-50%);
}