html,
body,
#root,
#root > div {
  height: 100%;
}

/* samp {
    height: 100vh;
} */
* {
  font-family: "Nunito", sans-serif;
}
#page-container {
  padding-top: 52px !important;
}

/* .pf {
    transform: scale(1.5);
    margin: 233px auto !important;
} */

.medium-editor-toolbar {
  visibility: visible !important;
  width: 100%;
  top: 0 !important;
  left: 0 !important;
}

.medium-editor-toobar-save,
.medium-editor-toobar-close {
  display: none !important;
}

* {
  margin: 0;
  padding: 0;
}
.editor {
  width: 80%;
  background-color: #fff;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 20px;
  padding: 30px;
}
.header-bar {
  height: 56px;
  width: 100%;
  background-color: #2c2d2d;
}

@font-face {
  font-family: "Brush Script MT";
  src: local("Brush Script MT"), url(./fonts/BRUSHSCI.ttf) format("truetype");
}

@font-face {
  font-family: "Courier New";
  src: local("Courier New"),
    url(./fonts/CourierPrime-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Garamond";
  src: local("Garamond"),
    url(./fonts/EBGaramond-VariableFont_wght.ttf) format("truetype");
}

@font-face {
  font-family: "Georgia";
  src: local("Georgia"), url(./fonts/Georgia.ttf) format("truetype");
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(./fonts/Helvetica.ttf) format("truetype");
}

@font-face {
  font-family: "Tahoma";
  src: local("Tahoma"),
    url(./fonts/Tahoma\ Regular\ font.ttf) format("truetype");
}

@font-face {
  font-family: "Times New Roman";
  src: local("Times New Roman"),
    url(./fonts/times\ new\ roman.ttf) format("truetype");
}

@font-face {
  font-family: "Trebuchet MS";
  src: local("Trebuchet MS"), url(./fonts/trebuc.ttf) format("truetype");
}

@font-face {
  font-family: "Verdana";
  src: local("Verdana"), url(./fonts/verdanab.ttf) format("truetype");
}

@font-face {
  font-family: "Arial";
  src: local("Arial"), url(./fonts/ARIAL.woff) format("woff");
}

@font-face {
  font-family: "JennaSue";
  src: local("JennaSue"), url(./fonts/JennaSue.ttf) format("truetype");
}

/* 'PWSignaturetwo', '', 'HoneyScript-Light', */
@font-face {
  font-family: "PWSignaturetwo";
  src: local("PWSignaturetwo"), url(./fonts/PWSignaturetwo.ttf) format("truetype");
}

@font-face {
  font-family: "daniel";
  src: local("daniel"), url(./fonts/daniel.ttf) format("truetype");
}

@font-face {
  font-family: "danielBold";
  src: local("daniel"), url(./fonts/danielbd.ttf) format("truetype");
}

@font-face {
  font-family: "danielBoldK";
  src: local("daniel"), url(./fonts/danielbk.ttf) format("truetype");
}

@font-face {
  font-family: "HoneyScript-Light";
  src: local("HoneyScript-Light"), url(./fonts/HoneyScript-Light.ttf) format("truetype");
}
@font-face {
  font-family: "James_Fajardo";
  src: local("James_Fajardo"), url(./fonts/James_Fajardo.ttf) format("truetype");
}

@font-face {
  font-family: "JPHSL";
  src: local("JPHSL"), url(./fonts/JPHSL.TTF) format("truetype");
}

@font-face {
  font-family: "James_Fajardo";
  src: local("James_Fajardo"), url(./fonts/James_Fajardo.ttf) format("truetype");
}
.nunito-font {
  font-family: "nunito";
}
img.ReactCrop__image{border: 1px solid #0a7859}
pre {
  color: black;
  padding: 16px;
  overflow: auto;
  font-size: 95%;
  line-height: 1.45;
  background-color: white;
  border-radius: 6px;
}